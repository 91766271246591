






















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
	USER_UPDATE,
	USER_UPDATED,
	USER_LOADED,
	USER_CREATE,
} from "@/store/actions/user";
import {
	REGION_REQUEST,
	REGION_LOADED,
	USER_ROLE_REQUEST,
	USER_ROLE_LOADED,
} from "@/store/actions/main";
import { User } from "@/models/User";

@Component({})
export default class UsersForm extends Vue {
	private form: any = {};
	private imageUrl: any = "";
	private loading: boolean = false;
	private show: boolean = false;
	private regions: any = [];
	private userRoles: any = [];
	//Snackbar
	private messageText = "Не удалось выполнить";
	private messageColor = "red";
	private message = false;

	private imageLoaded: boolean = false;
	private preview: string = "";
	$refs!: {
		inp: any;
	};
	//Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];

		needReload.push(
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		);
		needReload.push(
			this.needReload(
				this.$store.getters.USER_ROLE_TABLE,
				this.$store.getters.USER_ROLE_LOADED
			)
		);
		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;

		if (
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		) {
			this.$store.dispatch(REGION_REQUEST).then(() => {
				this.initIfReady();
			});
		}
		if (
			this.needReload(
				this.$store.getters.USER_ROLE_TABLE,
				this.$store.getters.USER_ROLE_LOADED
			)
		) {
			this.$store.dispatch(USER_ROLE_REQUEST).then(() => {
				this.initIfReady();
			});
		}
	}
	private initSelf() {
		console.log("INIT SELF upd");
		this.regions = this.$store.getters.REGION_TABLE.data;
		this.userRoles = this.$store.getters.USER_ROLE_TABLE.data;
		this.initStartOptions();
		this.loading = false;
		console.log("loading");
	}
	//Methods
	private editItem() {
		var converted = new FormData();
		Object.keys(this.form).forEach((key) => {
			console.log(key);
			converted.append(key, this.form[key]);
		});
		var data = {
			id: +this.$route.params.id,
			data: converted,
		};
		this.$store
			.dispatch(USER_UPDATE, data)
			.then(() => {
				this.messageText = "Пользователь " + this.form.fio + " изменен.";
				this.messageColor = "green";
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red";
			})
			.finally(() => {
				this.message = true;
			});
	}
	private createItem() {
		var converted = new FormData();

		Object.keys(this.form).forEach((key) => {
			console.log(key);
			converted.append(key, this.form[key]);
		});
		this.$store
			.dispatch(USER_CREATE, converted)
			.then(() => {
				this.messageText = "Пользователь " + this.form.fio + " добавлен.";
				this.messageColor = "green";
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red";
			})
			.finally(() => {
				this.message = true;
			});
	}
	private toString(str) {
		return str as string;
	}
	private isUpdatePage() {
		return this.$route.meta.isUpdate;
	}
	private initStartOptions() {
		var item;
		if (localStorage.getItem("userToEdit")) {
			console.log("update page");
			try {
				item = JSON.parse(this.toString(localStorage.getItem("userToEdit")));
				console.log("item init: ", item);
			} catch (e) {
				localStorage.removeItem("userToEdit");
			}
		} else {
			console.log("create page");
		}
		/*
		Object.keys(item).forEach((key) => {
			this.form[key] = item[key];
		});
		*/
		//console.log(this.props.isUpdatePage);
		if (this.isUpdatePage()) {
			Object.keys(item).forEach((key) => {
				this.form[key] = item[key];
			});
			this.form.avatar_path = this.getSrc(item.avatar_path);
			//this.userId = item.id;
			console.log("form: ", this.form);
		}
	}
	//Image
	private createImage(file) {
		const reader = new FileReader();

		reader.onload = (e) => {
			let ev = {} as any;
			ev = e;
			this.imageUrl = ev.target.result;
		};
		reader.readAsDataURL(file);
	}
	private onFileChange(file) {
		if (!file) {
			return;
		}
		this.createImage(file);
	}
	private clearImage() {
		// this.preview = "";
		// this.imageLoaded = false;
		delete this.form.avatar_file;
		this.$forceUpdate();
	}
	private getSrc(address) {
		return process.env.VUE_APP_API_ENDPOINT + "/" + address;
	}
	//Style
	private imageSizePx() {
		switch (this.$vuetify.breakpoint.name) {
			case "xs":
				return "220px";
			case "sm":
				return "220px";
			case "md":
				return "220px";
			case "lg":
				return "220px";
			case "xl":
				return "220px";
		}
	}
	//Hooks
	private created() {
		var item;
		if (localStorage.getItem("userToEdit")) {
			item = JSON.parse(this.toString(localStorage.getItem("userToEdit")));
			this.$router.currentRoute.meta.breadcrumb[2].text = ""+item.username;
		}
		
	}
	private mounted() {
		this.tryToInitSelf();
	}
	private destroyed() {
		localStorage.removeItem("userToEdit");
	}
}
